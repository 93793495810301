import apiClient from "../services/apiClient";
import { useQuery } from '@tanstack/react-query';

interface IUserFeatureAccess {
	feature: string;
	is_allowed: boolean;
}




export function useUserFeatureAccessQuery() {

	async function getUserFeatureAccess() {
		const { data } = await apiClient.get(`/users/feature-access`)
		return data
	}

	return useQuery<IUserFeatureAccess[]>(['user-feature-access'], getUserFeatureAccess);
}
