import { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { useCompaniesQuery } from '../../api/reference';
import {
	TickerSelect
} from '../../components';
import { useAddWatchlistCompanies, useAddWatchlistCompaniesFile } from "../../api/watchlist";
import { ICompany } from "../../interfaces";
import { FileUpload, FileUploadProps } from "../FileUpload/FileUpload";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useUserFeatureAccessQuery } from "../../api/users"
import WarningIcon from '@mui/icons-material/Warning';

type addModeType = "manual" | "file";



function ManualSelect(props: {
	selectedCompanies: ICompany[],
	setSelectedCompanies: React.Dispatch<React.SetStateAction<ICompany[]>>
}) {

	const companiesQuery = useCompaniesQuery();

	return (
		<Box sx={{ mt: 2, pl: 2, pr: 2 }}>
			<TickerSelect
				title="Tickers"
				placeholder='Search tickers'
				minOpenLength={1}
				tickerOptions={companiesQuery.data || []}
				tickersSelected={props.selectedCompanies}
				loading={companiesQuery.isLoading}
				limitTags={100}
				disableChipDelete={false}
				handleTickerSelect={(event: React.SyntheticEvent, newValue: ICompany[]) => {
					props.setSelectedCompanies(newValue);
				}}
			/>
		</Box>
	)
}

function FileBox(props: {
	fileName: string,
	removeFile: () => void
}) {
	return (
		<Box border="1px solid" padding={2} borderRadius={2} sx={{ borderColor: (theme) => theme.palette.action.disabledBackground }}>
			<Stack direction="row"
				alignItems="center"
				spacing={3}
			>
				<Typography>{props.fileName}</Typography>

				<Tooltip title="Remove">
					<IconButton
						aria-label="close"
						onClick={props.removeFile}
						sx={{
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</Tooltip>

			</Stack>
		</Box>
	)
}

function FileSelect(props: {
	fileSelected: File | null,
	setFileSelected: React.Dispatch<React.SetStateAction<File | null>>,
	isFileUploadAllowed: boolean
}) {

	const fileUploadProp: FileUploadProps = {
		accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
		onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
			if (
				event.target.files !== null &&
				event.target?.files?.length > 0
			) {
				props.setFileSelected(event.target?.files[0]);
			}
		},
		onDrop: (event: React.DragEvent<HTMLElement>) => {
			props.setFileSelected(event.dataTransfer?.files[0]);
		},
	}

	if (!props.isFileUploadAllowed) {
		return (
			<Box border="1px solid" padding={2} borderRadius={2} sx={{ borderColor: (theme) => theme.palette.action.disabledBackground }}>

				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<WarningIcon color="warning" fontSize="large" />
					<Box>
						<Typography sx={{ ml: 1.5, fontWeight: 500 }} variant="subtitle1">
							File upload is disabled for your account.
						</Typography>
						<Typography sx={{ ml: 1.5, fontWeight: 400 }} color="text.secondary" variant="body1">
							Please reach out to support for assistance uploading your watchlist.
							<br />
							You can manually add tickers by going back and selecting them from the list.
						</Typography>
					</Box>
				</Box>
			</Box>
		)
	}


	if (props.fileSelected === null) {
		return (
			<>
				<FileUpload {...fileUploadProp} />
				<Typography sx={{ mt: 1, fontWeight: 400 }} color="text.secondary" variant="subtitle2">Upload a CSV or Excel file. The first column should be a list of ticker symbols with an optional country code (ex: AAPL, ANF US, QSR CN).</Typography>
			</>
		)
	} else {
		return <FileBox fileName={props.fileSelected.name} removeFile={() => props.setFileSelected(null)} />
	}

}



export default function WatchlistAddCompaniesDialog(
	props: {
		watchlistId: number,
		watchlistName: string,
		navigate: NavigateFunction,
		isOpen: boolean,
		handleDialogClose: () => void,
	}) {


	const [addMode, setAddMode] = useState<addModeType | null>(null);
	const [errorMsg, setErrorMsg] = useState<string>("");
	const [successMsg, setSuccessMsg] = useState<string>("");

	const userFeatureAccessQuery = useUserFeatureAccessQuery();
	// check if feauture "watchlist_file_upload" is allowed (default is true)
	const fileUploadAccess = userFeatureAccessQuery.data?.find(i => i.feature === "watchlist_file_upload")?.is_allowed;
	const isFileUploadAllowed = fileUploadAccess === undefined ? true : fileUploadAccess;

	// for manual ticker select
	const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
	// for file upload
	const [fileSelected, setFileSelected] = React.useState<File | null>(null)

	// mutations (add companies or companies file)
	const addWatchlistCompanies = useAddWatchlistCompanies(props.watchlistId);
	const addWatchlistCompaniesFile = useAddWatchlistCompaniesFile(props.watchlistId);

	function ModeButtons() {
		return (
			<Stack direction="row" spacing={2} justifyContent="center"
				alignItems="center" sx={{ mt: 3 }}
			>
				<Button sx={{ minWidth: 180 }} size="large" variant="outlined" onClick={() => setAddMode("manual")} >Select tickers</Button>
				<Typography>OR</Typography>
				<Button sx={{ minWidth: 180 }} size="large" variant="outlined" onClick={() => setAddMode("file")} >Upload Excel or CSV file</Button>
			</Stack>
		)
	}


	function onTickerListSubmit() {

		setErrorMsg("");
		setSuccessMsg("");

		if (selectedCompanies.length === 0) {
			return;
		}

		const company_ids = selectedCompanies.map(i => i.id);

		addWatchlistCompanies.mutate(company_ids,
			{
				onSuccess: () => {
					setSuccessMsg("Tickers were successfully added to the watchlist.")
					props.handleDialogClose();
					setSelectedCompanies([]);
					setAddMode(null);
				},
				onError: (error) => {
					setErrorMsg("An error occured. Please try again.");
				}
			}
		);


	}

	function onFileSubmit() {

		setErrorMsg("");
		setSuccessMsg("");

		if (fileSelected === null) {
			setErrorMsg("Please select a file.");
			return;
		}

		const fileSize = fileSelected.size;
		if (fileSize > 2e+8) {
			setErrorMsg("File size exceeds maximum (200mb)");
			return;
		}

		addWatchlistCompaniesFile.mutate(fileSelected,
			{
				onSuccess: (response) => {

					const data = response.data;
					if (data.num_matched > 0) {
						setSuccessMsg(`${data.num_matched} tickers were successfully added to the watchlist.`);
					}

					if (data.num_not_matched > 0) {
						if (data.not_matched_tickers.length < 20) {
							setErrorMsg(`${data.num_not_matched} tickers were not found: ${data.not_matched_tickers.join(", ")}`);
						} else {
							setErrorMsg(`${data.num_not_matched} tickers were not found.`);
						}

					}

					props.handleDialogClose();
					setFileSelected(null);
					setAddMode(null);
				},

				onError: (error: any | AxiosError) => {

					const msg = error.response?.data?.detail;
					if (msg) {
						if (Array.isArray(msg)) {
							setErrorMsg(msg[0].msg);
						} else {
							setErrorMsg(msg);
						}
					} else {
						setErrorMsg("An error occured. Please check the file try again.");
					}

				}

			}
		);

	}


	return (
		<>
			<Dialog open={props.isOpen} onClose={props.handleDialogClose} fullWidth

				PaperProps={{
					sx: {
						minHeight: 200,
						backgroundImage: 'none'
					}
				}}
			>
				<DialogTitle>
					Add Companies: <Typography component="p" sx={{ color: 'warning.light', fontWeight: 400 }} variant="h6" display="inline">{props.watchlistName}</Typography>
					<IconButton
						aria-label="close"
						onClick={props.handleDialogClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>

				</DialogTitle>

				<DialogContent>

					{
						addMode === "manual" ? <ManualSelect selectedCompanies={selectedCompanies} setSelectedCompanies={setSelectedCompanies} /> : addMode === "file" ? <FileSelect fileSelected={fileSelected} setFileSelected={setFileSelected} isFileUploadAllowed={isFileUploadAllowed} /> : <ModeButtons />
					}

				</DialogContent>


				{addMode !== null && !addWatchlistCompanies.isLoading && !addWatchlistCompaniesFile.isLoading ?
					<DialogActions>
						<Button size="small" variant="text" onClick={() => { setAddMode(null); setFileSelected(null); setSelectedCompanies([]); }}>Back</Button>
						{/* <Button size="small" variant="text" onClick={() => { props.handleDialogClose(); }}>Cancel</Button> */}

						{
							addMode === "file" ? <Button disabled={fileSelected === null} size="small" variant="contained" onClick={() => { onFileSubmit(); }}>Upload File</Button>
								: <Button disabled={selectedCompanies.length === 0} size="small" variant="contained" onClick={() => { onTickerListSubmit(); }}>Add Tickers</Button>
						}


					</DialogActions>
					: <></>
				}

				{(addWatchlistCompanies.isLoading || addWatchlistCompaniesFile.isLoading) &&
					<DialogActions>
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					</DialogActions>
				}


			</Dialog >


			<Snackbar open={successMsg !== "" || errorMsg !== ""} autoHideDuration={6000}
				onClose={() => { setSuccessMsg(""); setErrorMsg(""); }}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<div>
					{successMsg && <MuiAlert variant="filled" severity="success">
						{successMsg}
					</MuiAlert>}

					<br />

					{errorMsg && <MuiAlert variant="filled" severity="error">
						{errorMsg}
					</MuiAlert>
					}

				</div>

			</Snackbar>


		</>
	);

}

